
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CreateActivityResultPayload": [
      "CreateActivityResultSuccess",
      "NotAuthenticatedError"
    ],
    "CreateBookQuizResultPayload": [
      "CreateBookQuizResultSuccess",
      "NotAuthenticatedError"
    ],
    "CreatePeakQuizResultPayload": [
      "CreatePeakQuizResultSuccess",
      "NotAuthenticatedError"
    ],
    "PrimaryGoal": [
      "QuestGoalPeak"
    ]
  }
};
      export default result;
    