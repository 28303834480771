import Service, { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import type { FeatureService } from '@blakeelearning/features'
import { graphql } from 'fast-phonics-client/graphql'
import { useMutation } from 'fast-phonics-client/resources/mutation'
import type SessionService from './session'
import type RouterService from '@ember/routing/router-service'

const SESSION_STORAGE_KEY = 'fastPhonicsClient.debugMode.enabled'

export const resetPeakProgressMutationDocument = graphql(/* GraphQL */ `
  mutation ResetPeakProgress($input: StudentProgressPeakResetInput!) {
    studentProgressPeakReset(input: $input) {
      student {
        ... on Student {
          id
        }
        __typename
      }
    }
  }
`)

export const resetPlacementTestProgressMutationDocument = graphql(
  /* GraphQL */ `
    mutation ResetPlacementTestProgress {
      studentProgressPlacementTestReset {
        student {
          ... on Student {
            id
          }
          __typename
        }
      }
    }
  `,
)

/**
 * Tracks the enabled / disabled state of the app's Debug Mode
 *
 * This service uses an underlying SessionStorage key to persist the
 * state of debug mode, combining that data with app config to decide
 * whether debug mode should be enabled.
 *
 * This computation is then set in the tracked `enabled` property, so
 * consumers can use it in situations where autotracking is required.
 */
export default class DebugModeService extends Service {
  @service declare features: FeatureService

  @service declare session: SessionService

  @service declare router: RouterService

  @tracked enabled = this._shouldEnable()

  resetPeakProgressMutation = useMutation(
    this,
    resetPeakProgressMutationDocument,
  )

  resetPlacementTestProgressMutation = useMutation(
    this,
    resetPlacementTestProgressMutationDocument,
  )

  get debugAllowed(): boolean {
    const client = this.features.getClient()
    return client.getBooleanValue('debugAllowedFF', false)
  }

  enable(): void {
    if (this.debugAllowed) {
      this._setSessionStorage(true)
      const shouldEnable = this._shouldEnable()
      this.enabled = shouldEnable
    }
  }

  disable(): void {
    if (this.debugAllowed) {
      this._setSessionStorage(false)
      this.enabled = false
    }
  }

  _shouldEnable(): boolean {
    try {
      return this.debugAllowed && this._getSessionStorage() === 'true'
    } catch (error) {
      console.error(error)
      return false
    }
  }

  _getSessionStorage(): string | null {
    const sessionStorageItem = sessionStorage.getItem(SESSION_STORAGE_KEY)
    return sessionStorageItem
  }

  _setSessionStorage(value: boolean): void {
    try {
      sessionStorage.setItem(SESSION_STORAGE_KEY, value ? 'true' : 'false')
    } catch (err) {
      console.warn(`Unable to set sessionStorage item "${SESSION_STORAGE_KEY}"`)
      console.error(err)
    }
  }

  async resetPeakProgress(peak: number, positionInPeak: number) {
    if (this.enabled) {
      await this.resetPeakProgressMutation.current.mutate({
        variables: {
          input: {
            peak,
            positionInPeak,
          },
        },
      })
      await this.session.reloadStudent({ include: 'progress' })
      void this.router.replaceWith('/')
    }
  }

  async resetPlacementTestProgress() {
    if (this.enabled) {
      await this.resetPlacementTestProgressMutation.current.mutate({})
      await this.session.reloadStudent({ include: 'progress' })
      void this.router.replaceWith('/')
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'debug-mode': DebugModeService
  }
}
