import type Owner from '@ember/owner'
import Service, { inject as service } from '@ember/service'
import { ContentLoader, activityAccent } from '@blakeelearning/content-loader'
import type { Manifest } from '@blakeelearning/content-loader-core'
import config from 'fast-phonics-client/config/environment'
import type SessionService from './session'

export default class JesterLoaderService extends Service {
  @service()
  declare session: SessionService

  loader: ContentLoader

  constructor(owner: Owner) {
    super(owner)

    this.loader = new ContentLoader({
      variant: activityAccent(this.session.accent),
      framework: 'jester',
      config: config.contentLoader.jester,
    })
  }

  load(manifests: Manifest[], variables?: Record<string, unknown>) {
    return this.loader.load(manifests, variables ?? {})
  }
}

declare module '@ember/service' {
  interface Registry {
    'jester-loader': JesterLoaderService
  }
}
