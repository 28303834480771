import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FullViewport\n  {{content-interactive\n    this.loader\n    manifests=@manifests\n    variables=this.variables\n    externalController=this.externalController\n    onError=this.onError\n    signal=this.signal\n  }}\n  data-test-content-interactive\n  class=\"flex justify-center items-center\"\n  ...attributes\n/>", {"contents":"<FullViewport\n  {{content-interactive\n    this.loader\n    manifests=@manifests\n    variables=this.variables\n    externalController=this.externalController\n    onError=this.onError\n    signal=this.signal\n  }}\n  data-test-content-interactive\n  class=\"flex justify-center items-center\"\n  ...attributes\n/>","moduleName":"fast-phonics-client/components/content-interactive.hbs","parseOptions":{"srcName":"fast-phonics-client/components/content-interactive.hbs"}});
import Component from '@glimmer/component'
import { assert } from '@ember/debug'
import { inject as service } from '@ember/service'
import type { ExternalController, Logger } from '@blakeelearning/messenger'
import {
  createExternalController,
  isContentSpec,
  isContentActions,
} from '@blakeelearning/messenger'
import config from 'fast-phonics-client/config/environment'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type { FeatureService } from '@blakeelearning/features'
import type SessionService from 'fast-phonics-client/services/session'
import { action } from '@ember/object'
import type JesterLoaderService from 'fast-phonics-client/services/jester-loader'

const { APP } = config

interface ContentInteractiveComponentArgs {
  Element: HTMLElement
  Args: {
    contentActions: unknown
    contentSpec: unknown
    accentOverride?: string
    manifests: [key: string, url: string][]
    variables: Record<string, unknown>
  }
}

export default class ContentInteractive extends Component<ContentInteractiveComponentArgs> {
  @service declare errorHandler: ErrorHandlerService

  @service declare features: FeatureService

  @service declare session: SessionService

  @service declare jesterLoader: JesterLoaderService

  abortController = new AbortController()

  get signal() {
    return this.abortController.signal
  }

  get loader() {
    return this.jesterLoader.loader
  }

  get externalController(): ExternalController {
    const { contentActions, contentSpec } = this.args

    assert(
      '@contentSpec must be a valid contentSpec object',
      isContentSpec(contentSpec),
    )

    assert(
      '@contentActions must be a valid contentActions object for this contentSpec',
      isContentActions(contentActions, contentSpec),
    )

    let logger: Logger | undefined

    if (APP.enableMessengerLogging) {
      logger = (name, event, ...args) => {
        console.log(
          '%c[%s :: %s]',
          'color: lavender; background-color: #836bb6; font-weight: bold',
          name,
          event,
          ...args,
        )
      }
    }

    return createExternalController(contentActions, contentSpec, {
      onUnrecoverableError: (error) => {
        this.errorHandler.handleUnrecoverableError(error)
      },
      logger,
    })
  }

  get variables() {
    const client = this.features.getClient()
    return {
      ...this.args.variables,
      features: [
        'jesterLoaderRetryFF',
        'jesterCssFontLoadingFF',
        'disable_ios_video_hack',
      ].filter((flag) => client.getBooleanValue(flag, false)),
    }
  }

  @action
  onError(error: unknown) {
    if (this.signal.aborted) {
      console.log(this.signal.reason)
    } else {
      this.errorHandler.handleUnrecoverableError(error)
    }
  }

  override willDestroy(): void {
    this.abortController.abort('Destroying interactive')
    super.willDestroy()
  }
}
