/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  mutation ResetPeakProgress($input: StudentProgressPeakResetInput!) {\n    studentProgressPeakReset(input: $input) {\n      student {\n        ... on Student {\n          id\n        }\n        __typename\n      }\n    }\n  }\n": types.ResetPeakProgressDocument,
    "\n    mutation ResetPlacementTestProgress {\n      studentProgressPlacementTestReset {\n        student {\n          ... on Student {\n            id\n          }\n          __typename\n        }\n      }\n    }\n  ": types.ResetPlacementTestProgressDocument,
    "\n  fragment StudentQuestDataFragment on Student {\n    id\n    quest {\n      primaryGoal {\n        __typename\n        goalType: __typename\n        ... on QuestGoalPeak {\n          rewardAmount\n          id\n          peak\n          progressCurrent\n          progressTotal\n          status\n        }\n      }\n    }\n  }\n": types.StudentQuestDataFragmentFragmentDoc,
    "\n  query GetStudentQuest {\n    student {\n      ... on Student {\n        ...StudentQuestDataFragment\n        __typename\n      }\n    }\n  }\n": types.GetStudentQuestDocument,
    "\n  mutation StudentQuestGoalAcknowledgeComplete(\n    $input: StudentQuestGoalAcknowledgeCompleteInput!\n  ) {\n    studentQuestGoalAcknowledgeComplete(input: $input) {\n      student {\n        ... on Student {\n        ...StudentQuestDataFragment\n        __typename\n      }\n      }\n    }\n  }\n": types.StudentQuestGoalAcknowledgeCompleteDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPeakProgress($input: StudentProgressPeakResetInput!) {\n    studentProgressPeakReset(input: $input) {\n      student {\n        ... on Student {\n          id\n        }\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPeakProgress($input: StudentProgressPeakResetInput!) {\n    studentProgressPeakReset(input: $input) {\n      student {\n        ... on Student {\n          id\n        }\n        __typename\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ResetPlacementTestProgress {\n      studentProgressPlacementTestReset {\n        student {\n          ... on Student {\n            id\n          }\n          __typename\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation ResetPlacementTestProgress {\n      studentProgressPlacementTestReset {\n        student {\n          ... on Student {\n            id\n          }\n          __typename\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StudentQuestDataFragment on Student {\n    id\n    quest {\n      primaryGoal {\n        __typename\n        goalType: __typename\n        ... on QuestGoalPeak {\n          rewardAmount\n          id\n          peak\n          progressCurrent\n          progressTotal\n          status\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment StudentQuestDataFragment on Student {\n    id\n    quest {\n      primaryGoal {\n        __typename\n        goalType: __typename\n        ... on QuestGoalPeak {\n          rewardAmount\n          id\n          peak\n          progressCurrent\n          progressTotal\n          status\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudentQuest {\n    student {\n      ... on Student {\n        ...StudentQuestDataFragment\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStudentQuest {\n    student {\n      ... on Student {\n        ...StudentQuestDataFragment\n        __typename\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StudentQuestGoalAcknowledgeComplete(\n    $input: StudentQuestGoalAcknowledgeCompleteInput!\n  ) {\n    studentQuestGoalAcknowledgeComplete(input: $input) {\n      student {\n        ... on Student {\n        ...StudentQuestDataFragment\n        __typename\n      }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation StudentQuestGoalAcknowledgeComplete(\n    $input: StudentQuestGoalAcknowledgeCompleteInput!\n  ) {\n    studentQuestGoalAcknowledgeComplete(input: $input) {\n      student {\n        ... on Student {\n        ...StudentQuestDataFragment\n        __typename\n      }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;